





















































import { Component, Vue } from 'vue-property-decorator'
import { getLatestVersionInfo } from '@/pages/videoGame/api'
import { callAppFunc_closeCurrentWebview } from '@/common/utils/bridge'
import { getAppLanguage } from '@/common/utils'
import { CommonModule } from '@/store/modules/common'
@Component
export default class extends Vue {
  updateCoin: string | number = ''
  checkVersion = 0 //版本更新检测 0未更新 1已是最新版本 2已领取奖励
  language = getAppLanguage()
  async init() {
    await CommonModule.getToken()
    const { data } = await getLatestVersionInfo()
    this.updateCoin = data.updateCoin
    this.checkVersion = data.checkVersion
  }
  get contentText() {
    if (this.language === 'zh_CN') {
      return `金币奖励：更新到最新版本，可领取${this.updateCoin}金币`
    } else {
      return `tagga mukaptati：ag yegi naxiriga yegilisigiz，${this.updateCoin} taggiga erixisiz`
    }
  }
  mounted() {
    this.init()
  }
  close() {
    callAppFunc_closeCurrentWebview()
  }
}
